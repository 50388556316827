export const PRODUCTS = {
  data: {
    content: [
      {
        productCode: '5006538447',
        productName: '네티 랩 썸머니트',
        productImg: ['https://image.msscdn.net/images/goods_img/20240509/4118941/4118941_17152412559989_125.jpg'],
        productInfo: null,
        category: ['nnn'],
        genreCode: '0010106',
        payment: 3,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'TWEE',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 6,
        oprice: 29900,
        rprice: 0,
      },
      {
        productCode: 'WBD1L05514BE',
        productName: '데일리 펀칭 하프 니트 [BEIGE] / WBD1L05514',
        productImg: ['https://image.msscdn.net/images/goods_img/20240206/3851506/3851506_17072151403131_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'GENERALIDEA',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 8,
        oprice: 47000,
        rprice: 42000,
      },
      {
        productCode: '20FW-NC_001',
        productName: '크루넥 리브 니트 스웨터_블랙',
        productImg: ['https://image.msscdn.net/images/goods_img/20201202/1710327/1710327_1_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BLOND9',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 22,
        oprice: 65600,
        rprice: 32800,
      },
      {
        productCode: '8883',
        productName: '리프 크로쉐 하프 카디건 브라운',
        productImg: ['https://image.msscdn.net/images/goods_img/20230420/3250078/3250078_17131749626975_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'AFTERPRAY',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 11,
        oprice: 129000,
        rprice: 0,
      },
      {
        productCode: 'DFWMKN4206IV',
        productName: '발롱 리본 스퀘어 니트 탑 [IVORY]',
        productImg: ['https://image.msscdn.net/images/goods_img/20240403/4021006/4021006_17128960480937_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'DRAWFIT WOMEN',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 12,
        oprice: 66000,
        rprice: 59400,
      },
      {
        productCode: '23BERDEKN01',
        productName: '메쉬 그래픽 니트',
        productImg: ['https://image.msscdn.net/images/goods_img/20230810/3457284/3457284_16916461931402_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BER DE NOIR',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 13,
        oprice: 67000,
        rprice: 0,
      },
      {
        productCode: 'MDKT075',
        productName: '[6color] 골지 라운드넥 반팔 니트 MDKT075',
        productImg: ['https://image.msscdn.net/images/goods_img/20240226/3900937/3900937_17143707163070_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'MONGDOL',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 14,
        oprice: 48000,
        rprice: 33600,
      },
      {
        productCode: '1MG24CKN103IVR',
        productName: 'W CLASSIC LOGO HALF KNIT ivory',
        productImg: ['https://image.msscdn.net/images/goods_img/20240222/3891969/3891969_17097021414100_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'MARITHÉ',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 15,
        oprice: 129000,
        rprice: 122550,
      },
      {
        productCode: 'GHBNT82(GRAY)',
        productName: '베론 니트 카라반팔티 GHBNT82(GRAY)',
        productImg: ['https://image.msscdn.net/images/goods_img/20220510/2547886/2547886_1_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'GRAYBLVD',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 16,
        oprice: 79000,
        rprice: 39800,
      },
      {
        productCode: '5007166888',
        productName: '데일리 케이블 칼라 니트 : 8컬러',
        productImg: ['https://image.msscdn.net/images/goods_img/20240318/3963304/3963304_17110701638574_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'INTEMPOMOOD',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 17,
        oprice: 56000,
        rprice: 39200,
      },
      {
        productCode: '매쉬드카라니트 웜그레이',
        productName: '매쉬드카라니트 웜그레이',
        productImg: ['https://image.msscdn.net/images/goods_img/20230526/3328479/3328479_16860258990568_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BY.L',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 18,
        oprice: 55000,
        rprice: 44000,
      },
      {
        productCode: '2305SS',
        productName: '소프트 부클 배색 라운드 반팔 니트 슬리브 티셔츠',
        productImg: ['https://image.msscdn.net/images/goods_img/20230509/3284399/3284399_16838760869902_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'PLACE STUDIO',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 19,
        oprice: 55000,
        rprice: 29900,
      },
      {
        productCode: 'm1037_240311',
        productName: '크로쉐 펄 니트 가디건_Classic Navy',
        productImg: ['https://image.msscdn.net/images/goods_img/20240416/4059036/4059036_17133299363856_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'LAFUDGE FOR WOMAN',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 20,
        oprice: 75000,
        rprice: 59800,
      },
      {
        productCode: '5000128017',
        productName: '메탈 슬리브리스-스카이블루',
        productImg: ['https://image.msscdn.net/images/goods_img/20230531/3334393/3334393_16854992721990_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'ODER',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 21,
        oprice: 32900,
        rprice: 0,
      },
      {
        productCode: 'SA23SSMK17BL',
        productName: '클래식 베이직 로고 와펜 하프 니트 스위밍 블루',
        productImg: ['https://image.msscdn.net/images/goods_img/20230411/3225027/3225027_16816997649180_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'SATUR',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 9,
        oprice: 119000,
        rprice: 0,
      },
      {
        productCode: 'TRSKN26KH',
        productName: '워셔블 부클 오픈 카라 하프 니트 (DUSTY KHAKI)',
        productImg: ['https://image.msscdn.net/images/goods_img/20240415/4055985/4055985_17134228493408_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'TRILLION',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 23,
        oprice: 49800,
        rprice: 39800,
      },
      {
        productCode: 'EPE2ER2200',
        productName: '여성) 라운드 케이블 반팔 스웨터_EPE2ER2200',
        productImg: ['https://image.msscdn.net/images/goods_img/20240402/4017346/4017346_17120419593932_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'PROJECTM',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 24,
        oprice: 59900,
        rprice: 20930,
      },
      {
        productCode: 'UA2SK5A_22',
        productName: '여성 케이블 반팔 니트[IVORY](UA2SK5A_22)',
        productImg: ['https://image.msscdn.net/images/goods_img/20240227/3903772/3903772_17090122930868_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'UCLA',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 25,
        oprice: 59000,
        rprice: 29000,
      },
      {
        productCode: '23S',
        productName: '소프트 쿨 터치 라운드 슬리브 퍼프 크롭 반팔 니트티',
        productImg: ['https://image.msscdn.net/images/goods_img/20230530/3331986/3331986_16867048776071_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'PLACE STUDIO',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 26,
        oprice: 72000,
        rprice: 35900,
      },
      {
        productCode: 'S2403UCPTOKN001',
        productName: '베티붑 데미지 슬릿 니트_(2 Colors)',
        productImg: ['https://image.msscdn.net/images/goods_img/20240327/3997219/3997219_17115235010966_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'ULKIN CANVAS',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 27,
        oprice: 59000,
        rprice: 39000,
      },
      {
        productCode: '114551HY20',
        productName: '슬리브리스 레이어드 니트 아이보리 (114551HY20)',
        productImg: ['https://image.msscdn.net/images/goods_img/20240430/4097862/4097862_17144601638166_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: '8SECONDS',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 28,
        oprice: 59900,
        rprice: 0,
      },
      {
        productCode: 'WBD2L05516BL',
        productName: '라운드 카라 부클 하프 니트 [BLUE] / WBD2L05516',
        productImg: ['https://image.msscdn.net/images/goods_img/20240206/3851513/3851513_17072122479526_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'GENERALIDEA',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 29,
        oprice: 47000,
        rprice: 42000,
      },
      {
        productCode: 'MDKT045',
        productName: '[From ITALY / 7color] 세미크롭 브이넥 반팔니트 MDKT045',
        productImg: ['https://image.msscdn.net/images/goods_img/20230405/3207804/3207804_17123075015095_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'MONGDOL',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 30,
        oprice: 49000,
        rprice: 39000,
      },
      {
        productCode: 'SEKT024',
        productName: '키홀 케이블 카라 하프 니트 [6 COLOR]_SEKT024',
        productImg: ['https://image.msscdn.net/images/goods_img/20240329/4005101/4005101_17150467373901_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'SECONDMONO',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 31,
        oprice: 49000,
        rprice: 29800,
      },
      {
        productCode: 'DFWMKN4207LE',
        productName: '라인 배색 부클 반팔 니트 [LEMON]',
        productImg: ['https://image.msscdn.net/images/goods_img/20240403/4021010/4021010_17128967596878_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'DRAWFIT WOMEN',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 32,
        oprice: 62000,
        rprice: 55800,
      },
      {
        productCode: 'm1037_905570',
        productName: 'CABLE HENRY NECK PULLOVER_PINK',
        productImg: ['https://image.msscdn.net/images/goods_img/20220712/2660156/2660156_1_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'PINK PINEAPPLE',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 33,
        oprice: 29000,
        rprice: 0,
      },
      {
        productCode: 'Z-1',
        productName: '케이블 헨리넥 하프 니트 (블랙)',
        productImg: ['https://image.msscdn.net/images/goods_img/20230531/3336608/3336608_16855208403197_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'DEVERRMAN',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 34,
        oprice: 69000,
        rprice: 62100,
      },
      {
        productCode: 'KBDUUKS001KBDUUKS002',
        productName: '[SET](린넨+케이블) COOL 워셔블 하프 니트',
        productImg: ['https://image.msscdn.net/images/goods_img/20230313/3143791/3143791_17158486541023_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'KIIMUIR',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 35,
        oprice: 77900,
        rprice: 45900,
      },
      {
        productCode: '53507',
        productName: '053507 폴로넥 반팔 스웨터_9color',
        productImg: ['https://image.msscdn.net/images/goods_img/20230303/3119357/3119357_16801371777300_125.png'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'GIORDANO',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 36,
        oprice: 59800,
        rprice: 39000,
      },
      {
        productCode: '23SS2_07',
        productName: '데미지 슬릿 브이넥 니트 탑_IVORY',
        productImg: ['https://image.msscdn.net/images/goods_img/20230601/3338992/3338992_16855987366772_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'HARDCORE HAPPINESS',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 7,
        oprice: 64000,
        rprice: 51200,
      },
    ],
    pageable: {
      pageNumber: 0,
      pageSize: 30,
      sort: {
        empty: false,
        sorted: true,
        unsorted: false,
      },
      offset: 0,
      paged: true,
      unpaged: false,
    },
    last: false,
    totalElements: 6300,
    totalPages: 210,
    size: 30,
    number: 0,
    sort: {
      empty: false,
      sorted: true,
      unsorted: false,
    },
    first: true,
    numberOfElements: 30,
    empty: false,
  },
  status: 200,
  statusText: '',
  headers: {
    'cache-control': 'no-cache, no-store, max-age=0, must-revalidate',
    'content-type': 'application/json',
    expires: '0',
    pragma: 'no-cache',
  },
  config: {
    transitional: {
      silentJSONParsing: true,
      forcedJSONParsing: true,
      clarifyTimeoutError: false,
    },
    adapter: ['xhr', 'http'],
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1,
    maxBodyLength: -1,
    env: {},
    headers: {
      Accept: 'application/json, text/plain, */*',
    },
    method: 'get',
    url: 'http://localhost:8080/product',
  },
  request: {},
};

export const PRODUCT_BRAND = {
  data: {
    content: [
      {
        productCode: '20FW-NC_001',
        productName: '크루넥 리브 니트 스웨터_블랙',
        productImg: ['https://image.msscdn.net/images/goods_img/20201202/1710327/1710327_1_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010106',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BLOND9',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 22,
        oprice: 65600,
        rprice: 32800,
      },
      {
        productCode: 'B21-214',
        productName: '오픈 카라 펀칭 니트_베이지',
        productImg: ['https://image.msscdn.net/images/goods_img/20210507/1939727/1939727_2_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0010103',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BLOND9',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 536,
        oprice: 52900,
        rprice: 36900,
      },
      {
        productCode: 'B23F-11008',
        productName: '그레이스 울 리브 니트 가디건_그레이',
        productImg: ['https://image.msscdn.net/images/goods_img/20230803/3443237/3443237_16917258891024_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0020120',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BLOND9',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 1462,
        oprice: 81300,
        rprice: 56900,
      },
      {
        productCode: 'B22-301',
        productName: '코튼 카라 반팔 니트 가디건_블랙',
        productImg: ['https://image.msscdn.net/images/goods_img/20220426/2514195/2514195_1_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0020120',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BLOND9',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 1467,
        oprice: 67000,
        rprice: 46900,
      },
      {
        productCode: 'B23F-11015',
        productName: '소프트 케이블 카라 가디건_네이비',
        productImg: ['https://image.msscdn.net/images/goods_img/20230803/3443522/3443522_16917366531097_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0020120',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BLOND9',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 1481,
        oprice: 61300,
        rprice: 42900,
      },
      {
        productCode: 'B23SS-WD001',
        productName: '그린 스티치 원턱 데님 팬츠 [와이드핏]',
        productImg: ['https://image.msscdn.net/images/goods_img/20230508/3281330/3281330_16848934597170_125.jpg'],
        productInfo: null,
        category: [null],
        genreCode: '0030102',
        payment: 0,
        favorite: 0,
        review: 0,
        productRdate: '2024-06-01T02:08:00',
        isDeleted: 0,
        judge: 'NULL',
        productBrand: 'BLOND9',
        productGender: 1,
        productRegistrant: 'NULL',
        productNum: 2882,
        oprice: 59000,
        rprice: 39800,
      },
    ],
    pageable: {
      pageNumber: 0,
      pageSize: 30,
      sort: { empty: false, sorted: true, unsorted: false },
      offset: 0,
      paged: true,
      unpaged: false,
    },
    last: true,
    totalElements: 6,
    totalPages: 1,
    size: 30,
    number: 0,
    sort: { empty: false, sorted: true, unsorted: false },
    first: true,
    numberOfElements: 6,
    empty: false,
  },
  status: 200,
  statusText: '',
  headers: {
    'cache-control': 'no-cache, no-store, max-age=0, must-revalidate',
    'content-type': 'application/json',
    expires: '0',
    pragma: 'no-cache',
  },
  config: {
    transitional: {
      silentJSONParsing: true,
      forcedJSONParsing: true,
      clarifyTimeoutError: false,
    },
    adapter: ['xhr', 'http'],
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1,
    maxBodyLength: -1,
    env: {},
    headers: {
      Accept: 'application/json, text/plain, */*',
    },
    method: 'get',
    url: 'http://localhost:8080/product',
  },
  request: {},
};
